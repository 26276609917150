<template>
  <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white">
    <button
      type="button"
      class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
      @click="$emit('sidebarToggle')"
    >
      <MenuAlt2Icon
        class="h-6 w-6"
        aria-hidden="true"
      />
    </button>
    <!-- Navigation Bar -->
    <div class="flex flex-1 px-4 justify-between gap-3">
      <!-- Navigation Bar Content Here!!! -->
      <SearchInput />
      <div class="flex flex-row">
        <div class="flex items-center">
          <Button
            class="w-24 h-10"
            @btnClick="$router.push({ name: 'ScanWizard'})"
          >
            <template #default>
              <div class="inline-flex flex-row items-center">
                <SearchIcon
                  class="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                <span>
                  Scan
                </span>
              </div>
            </template>
          </Button>
        </div>
        <div class="flex items-center ml-2">
          <DropDownAvatarMenu />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/SearchInput.vue';
import DropDownAvatarMenu from '@/components/DropDownAvatarMenu.vue';
import Button from '@/components/Button.vue';

import {
  MenuAlt2Icon,
  SearchIcon,
} from '@heroicons/vue/outline'

export default {
  components: {
    MenuAlt2Icon,
    SearchIcon,
    SearchInput,
    DropDownAvatarMenu,
    Button,
  },

  emits: ['sidebarToggle'],
}
</script>

<style>

</style>