<template>
  <div class="flex w-screen h-screen bg-gray-50 overflow-y-auto">
    <Sidebar
      :sidebar-open="sidebarOpen"
      @sidebarToggle="sidebarToggle"
    />

    <div class="md:pl-64 flex flex-col flex-1 w-full h-full">
      <NavigationBar 
        @sidebarToggle="sidebarToggle"
      />
      
      <main>
        <div class="py-10 px-8 h-full relative">
          <slot name="content" />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import NavigationBar from '@/components/NavigationBar.vue';

export default {
  components: {
    Sidebar,
    NavigationBar,
  },

  data: () => ({
    sidebarOpen: false,
  }),

  methods: {
    sidebarToggle() {
      this.sidebarOpen = !this.sidebarOpen
    },
  },


}
</script>