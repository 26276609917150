<template>
  <MainLayout v-if="$route.meta.isAuthPage !== true">
    <template #content>
      <router-view v-slot="{ Component }">
        <transition
          name="scale"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </template>
  </MainLayout>

  <router-view v-else />
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  components: {
    MainLayout,
    
  },

  data: () => ({
    prevRoute: true,
  }),

  watch: {
    $route(newRoute, oldRoute) {
      if (newRoute.name !== oldRoute.name) {
        this.prevRoute = false;

        setTimeout(() => {
          this.prevRoute = true;
        }, 500);
      }
    }
  },

  created() {
    console.log(this.$route)
  },
}
</script>

<style>
  .scale-enter-active{
    transition: all 0.5s ease;
    transition-delay: .4s;
  }

  .scale-leave-active {
    transition: all 0.5s ease;
  }


  .scale-enter-from,
  .scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }

  .scale-slide-enter-active {
    position: absolute;
    transition: all 0.85s ease;
  }

  .scale-slide-leave-active {
    position: absolute;
    transition: all 0.85s ease;
  }


  .scale-slide-enter-from {
    left: -100%;
  }


  .scale-slide-enter-to {
    left: 0%;
  }


  .scale-slide-leave-from {
    transform: scale(1);
  }


  .scale-slide-leave-to {
    transform: scale(0.8);
  }
</style>