const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/targets',
    name: 'Targets',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/issues',
    name: 'Issues',
    component: () => import('@/views/Issues.vue'),
  },
  {
    path: '/scans',
    name: 'Scans',
    component: () => import('@/views/Scans.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/views/Reports.vue'),
  },
  {
    path: '/scan/wizard',
    name: 'ScanWizard',
    component: () => import('@/views/ScanWizard.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/auth/Index.vue'),
    redirect: {
      name: 'Login',
    },
    meta: {
      isAuthPage: true,
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: import('@/views/auth/Login.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: import('@/views/auth/Register.vue'),
      },
      {
        path: 'reset',
        name: 'ResetPassword',
        component: import('@/views/auth/ResetPassword.vue'),
      },
    ]
  },
];

export default routes;
