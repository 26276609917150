<template>
  <div>
    <TransitionRoot
      as="template"
      :show="sidebarOpen"
      class="shadow-xl"
    >
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="$emit('sidebarToggle')"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="$emit('sidebarToggle')"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </TransitionChild>

            <router-link
              :to="{ name: 'Home' }"
              class="flex items-center flex-shrink-0 px-4"
            >
              <img
                class="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                alt="Workflow"
              >
            </router-link>

            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.to"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                  active-class="bg-gray-100 text-gray-900"
                >
                  <component
                    :is="item.icon"
                    :class="[$route.name === item.to.name ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div
          class="flex-shrink-0 w-14"
          aria-hidden="true"
        >
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
        <router-link
          :to="{ name: 'Home' }"
          class="flex items-center flex-shrink-0 px-4"
        >
          <img
            class="h-8 w-auto"
            src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
            alt="Workflow"
          >
        </router-link>
        <div class="mt-5 flex-grow flex flex-col">
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              :to="item.to"
              class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
              active-class="bg-gray-100 text-gray-900"
            >
              <component
                :is="item.icon"
                :class="[$route.name === item.to.name ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']"
                aria-hidden="true"
              />
              {{ item.name }}
            </router-link>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  TemplateIcon,
  InboxIcon,
  UsersIcon,
  XIcon,
} from '@heroicons/vue/outline'

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

export default {
  components: {
    CalendarIcon,
    ChartBarIcon,
    FolderIcon,
    TemplateIcon,
    InboxIcon,
    UsersIcon,
    XIcon,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },

  props: {
    sidebarOpen: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['sidebarToggle'],

  data: () => ({
    navigation: [
      { name: 'Dashboard', to: { name : 'Home'}, icon: TemplateIcon, current: true },
      { name: 'Issues', to: { name : 'Issues'}, icon: UsersIcon, current: false },
      { name: 'Scans', to: { name : 'Scans'}, icon: FolderIcon, current: false },
      { name: 'Reports', to: { name : 'Reports'}, icon: CalendarIcon, current: false },
      { name: 'Targets', to: { name : 'Targets'}, icon: InboxIcon, current: false },
      { name: 'Notifications',to: {name : 'Notifications'}, icon: ChartBarIcon, current: false },
    ]
  }),
}
</script>

<style>

</style>